import { format, isToday, addMinutes } from 'date-fns';
import { APPOINTMENT_OFFSET_MINUTES } from '@/data/constants';
import http from '../httpConfig';

const handleTelemedicineData = (data, isDayToday) => {
  const now = parseInt(format(addMinutes(new Date(), APPOINTMENT_OFFSET_MINUTES), 'Hmm'), 10);
  const todayPlusOffsetMin = isToday(addMinutes(new Date(), APPOINTMENT_OFFSET_MINUTES));

  const timetable = Object.keys(data)
    .map((key) => ({
      time: key,
      label: String(key).slice(0, -3),
      ...data[key][0],
    }));

  if (isDayToday) {
    return todayPlusOffsetMin
      ? timetable
        .filter((item) => {
          const time = parseInt(item.time.replace(':', '').slice(0, -2), 10);
          return time > now;
        })
      : [];
  }

  return timetable;
};

const handlePresentialData = (data, isToday) => {
  const now = parseInt(format(addMinutes(new Date(), APPOINTMENT_OFFSET_MINUTES), 'Hmm'), 10);
  const timetable = data.map((time) => ({ time, label: String(time).slice(0, -3) }));

  return isToday
    ? timetable
      .filter((item) => {
        const time = parseInt(item.time.replace(':', '').slice(0, -2), 10);
        return time > now;
      })
    : timetable;
};

const availableTime = (
  uuid,
  startDate,
  medicalSpecialtyId,
  { latitude, longitude } = { latitude: null, longitude: null },
  paymentMethod,
) => {
  const prefix = `/health_place_partner/${uuid}`;

  const params = [
    { query: 'initialDate', value: startDate },
    { query: 'finalDate', value: startDate },
    { query: 'medicalSpecialty', value: medicalSpecialtyId },
    { query: 'paymentMethod', value: paymentMethod },
  ];

  if (latitude && longitude) {
    params.push({ query: 'latitude', value: latitude });
    params.push({ query: 'longitude', value: longitude });
  } else {
    params.push({ query: 'doctorOfficeType', value: 'TELEMEDICINE' });
  }

  const queryString = params.reduce(
    (acc, item) => `${acc.startsWith('?') ? `${acc}&` : '?'}${item.query}=${item.value}`,
    '',
  );

  const endpoint = (latitude && longitude)
    ? `${prefix}/doctor_office${queryString}`
    : `${prefix}/doctor_available_time${queryString}`;

  const getAvailableTime = () => new Promise((resolve, reject) => {
    http.get(endpoint)
      .then((res) => {
        const notAvailable = () => reject(new Error(
          'Não foram encontrados horários disponíveis para a especialidade escolhida.',
        ));

        // Telemedicine appointment
        if (
          typeof res.data === 'object'
          && Object.keys(res.data).includes(startDate)
        ) {
          const [year, month, day] = startDate.split('-');
          const today = new Date(year, month - 1, day);
          const timetable = handleTelemedicineData(res.data[startDate], isToday(today));

          if (timetable.length === 0) {
            notAvailable();
            return;
          }

          resolve(timetable);
          return;
        }

        // Presential appointment
        if (Array.isArray(res.data)) {
          const offices = [];

          res.data.forEach((result) => {
            const {
              'address_cep': cep,
              'address_complement': complement,
              'address_district': district,
              'address_street': street,
              'address_number': number,
              'address_city': city,
              'address_uf': uf,
              'doctor': doctorUuid,
              'doctor_id': doctorId,
              'doctorOffice': doctorOfficeUuid,
              'doctorOffice_id': doctorOfficeId,
              'doctorOffice_name': doctorOfficeName,
              distance,
              specialtyPrice,
            } = result;

            const [year, month, day] = startDate.split('-');
            const today = new Date(year, month - 1, day);
            const timetable = handlePresentialData(result.available[startDate], isToday(today));

            const office = {
              address: {
                cep,
                complement,
                district,
                street,
                number,
                city,
                uf,
                distance,
              },
              doctor: {
                id: doctorId,
                uuid: doctorUuid,
              },
              doctorOffice: {
                id: doctorOfficeId,
                uuid: doctorOfficeUuid,
                name: doctorOfficeName,
              },
              timetable,
              specialtyPrice,
            };

            offices.push(office);
          });

          // Remove offices without available periods
          for (let i = offices.length - 1; i >= 0; i -= 1) {
            if (offices[i].timetable.length === 0) {
              offices.splice(i, 1);
            }
          }

          if (offices.length > 0) {
            resolve(offices);
            return;
          }

          notAvailable();
          return;
        }

        if (res.data.length === 0) {
          notAvailable();
          return;
        }

        reject(new Error('Ocorreu um erro.'));
      })
      .catch(reject);
  });

  return {
    getAvailableTime,
  };
};

export default availableTime;
