<template>
  <section class="appointment-date">
    <div class="appointment-date__box">
      <h2 class="appointment-date__title">
        Escolha abaixo uma data
      </h2>

      <div class="appointment-date__wrapper">
        <div
          :class="{ 'appointment-date__date--disabled': ui.isLoading }"
          class="appointment-date__date"
          @click="openSafariDatepicker"
        >
          <span class="appointment-date__date-text">
            {{ appointmentDate | formattedDate }}
          </span>

          <img
            :src="require(`@/assets/img/icons/${partner.theme}-ico-pickdate.svg`)"
            class="appointment-date__date-icon"
          >
        </div>

        <input
          v-if="!isSafariDesktop"
          v-model="appointmentDate"
          :disabled="ui.isLoading"
          :min="today"
          type="date"
          class="appointment-date__date-input"
        >

        <div
          v-show="isSafariDesktop && ui.toggleSafariDatepicker"
          class="appointment-date__safari-datepicker"
        >
          <dr-calendar v-model="appointmentDateSafari" />
        </div>
      </div>
    </div>

    <div class="appointment-date__box">
      <h2 class="appointment-date__title">
        {{
          appointment.modality === 'presential'
            ? 'Consultórios disponíveis'
            : 'Escolha um horário'
        }}
      </h2>

      <div class="appointment-date__time">
        <transition name="blend">
          <div
            v-if="ui.isLoading"
            :key="'loader'"
            class="appointment-date__loader"
          >
            <div class="appointment-date__loader-wrapper">
              <p class="appointment-date__loader-text">
                Carregando horários disponíveis, aguarde...
              </p>

              <progress class="appointment-date__loader-bar" />
            </div>
          </div>
        </transition>

        <transition name="blend">
          <div
            v-if="appointment.modality === 'telemedicine' && !ui.isLoading && timetable.length > 0"
            class="appointment-date__time-wrapper"
          >
            <list-filter
              v-model="appointmentTime"
              :selected="appointmentTime"
              :disabled="ui.isLoading"
              :bigger-label="true"
              :options="timetable"
              class="appointment-date__time-list"
            />
          </div>
        </transition>

        <div
          v-if="
            appointment.modality === 'presential'
              && !ui.isLoading
              && offices.length > 0
          "
          class="appointment-date__time-wrapper"
        >
          <carousel
            :navigation-enabled="true"
            :navigation-next-label="`
              <i class='icon icon-arrow-right appointment-date__office-icon'></i>
            `"
            :navigation-prev-label="`
              <i class='icon icon-arrow-left appointment-date__office-icon'></i>
            `"
            :autoplay="false"
            :per-page="1"
            :adjustable-height="true"
            pagination-color="var(--color-gray-200)"
            pagination-active-color="var(--color-primary)"
            class="appointment-date__office"
          >
            <slide
              v-for="(office, index) in offices"
              :key="`presential-office-${index}`"
              class="appointment-date__office-slide"
            >
              <div class="appointment-date__office-card">
                <h4 class="appointment-date__office-name">
                  {{ office.doctorOffice.name }}
                </h4>

                <p class="appointment-date__office-text !pb-0 !mt-0">
                  {{ getAddress(office.address) }}
                </p>

                <div class="appointment-date__office-row !pt-0 !mt-0">
                  <a
                    :href="`https://maps.google.com/maps?q=${getAddress(office.address)}`"
                    target="_blank"
                    class="appointment-date__office-link ml-auto"
                  >
                    <span class="appointment-date__office-map">
                      [ver no mapa]
                    </span>
                  </a>
                </div>

                <div class="appointment-date__office-row">
                  <a
                    :href="`https://maps.google.com/maps?q=${getAddress(office.address)}`"
                    target="_blank"
                    class="appointment-date__office-link"
                  >
                    <span class="appointment-date__office-distance">
                      <i class="icon icon-location" />
                      {{ parseFloat(office.address.distance).toFixed(2) }}
                      km
                    </span>
                  </a>

                  <p class="appointment-date__office-price">
                    {{ parseFloat(office.specialtyPrice) | toCurrency }}
                  </p>
                </div>
              </div>

              <list-filter
                v-model="office.appointmentTime"
                :selected="office.appointmentTime"
                :disabled="ui.isLoading"
                :bigger-label="true"
                :options="office.timetable"
                class="appointment-date__time-list"
                @select="(time) => {
                  offices.forEach((office) => office.appointmentTime = null);
                  office.appointmentTime = time;
                }"
              />
            </slide>
          </carousel>
        </div>

        <transition name="blend">
          <div
            v-if="!ui.isLoading && ui.notFound"
            class="appointment-date__time-wrapper"
          >
            <template v-if="appointment.modality === 'telemedicine'">
              <p class="appointment-date__time-not-found">
                Não foram encontrados horários disponíveis na data escolhida.
              </p>
            </template>

            <template v-if="appointment.modality === 'presential'">
              <div class="flex flex-col items-center justify-start">
                <p class="appointment-date__time-not-found mt-3 mb-6">
                  Desculpe, não encontramos consultórios próximos do endereço
                  informado para a especialidade escolhida.
                </p>

                <p class="appointment-date__time-not-found mb-3">
                  Se desejar, faça uma nova busca com outro endereço ou clique
                  no botão abaixo para agendar uma teleconsulta.
                </p>

                <div class="appointment-date__call-to-action">
                  <ui-button
                    label="Agendar teleconsulta"
                    color="secondary"
                    @click="searchTelemedicineAppointment"
                  />
                </div>
              </div>
            </template>
          </div>
        </transition>
      </div>

      <h3
        v-if="
          (
            appointment.modality === 'presential'
            && !ui.isLoading
            && offices.length > 0
          )
            ||
            (
              appointment.modality === 'telemedicine'
              && !ui.isLoading
              && timetable.length > 0
            )
        "
        class="appointment-date__title--fuse"
      >
        *Horário de Brasília
      </h3>
    </div>

    <transition name="fade">
      <p
        v-if="payment.method.name !== 'prepaid'
          && payment.method.name !== 'voucher'
          && appointment.modality === 'telemedicine'
          && localAppointment
          && localAppointment.price
        "
        class="appointment-date__price"
      >
        O valor de consulta encontrado para este horário é de
        <span class="appointment-date__price--accent">
          {{ localAppointment.price | toCurrency }}
        </span>
      </p>
    </transition>

    <div class="appointment-date__call-to-action">
      <ui-button
        :disabled="ui.isLoading || !isValid"
        :label="
          payment.method.name === 'prepaid'
            ? 'Prosseguir para agendamento'
            : 'Prosseguir para pagamento'
        "
        @click="goToPayment"
      />
    </div>
  </section>
</template>

<script>
import {
  format,
  formatISO,
  parseISO,
  isBefore,
  subDays,
  addDays,
  addMinutes,
} from 'date-fns';
import { mapActions, mapGetters } from 'vuex';
import ptBr from 'date-fns/locale/pt-BR';
import { DrCalendar } from 'cm2tech-vue-calendar';
import { Carousel, Slide } from 'vue-carousel';

import { SEARCH_APPOINTMENTS_LIMIT } from '@/data/constants';

import availableTime from '@/api/appointmentScheduling/availableTime';

import toCurrency from '@/filters/convertToCurrency';

import scrollToSection from '@/mixins/scrollToSection';
import completeStep from '@/mixins/completeStep';
import handleError from '@/mixins/handleError';
import googleAnalytics from '@/mixins/googleAnalytics';

import UiButton from '@/components/ui/Button.vue';
import ListFilter from '@/components/ui/ListFilter.vue';

export default {
  name: 'AppointmentSchedulingDate',

  filters: {
    formattedDate(value) {
      const args = value.split('-');

      return format(
        new Date(
          parseInt(args[0], 10),
          parseInt(args[1], 10) - 1,
          parseInt(args[2], 10),
        ),
        'iiii\',\' d \'de\' MMMM \'de\' yyyy',
        { locale: ptBr },
      ).toLowerCase();
    },

    toCurrency,
  },

  mixins: [
    completeStep,
    scrollToSection,
    handleError,
    googleAnalytics,
  ],

  components: {
    ListFilter,
    UiButton,
    DrCalendar,
    Carousel,
    Slide,
  },

  data() {
    return {
      today: null,
      timetable: [],
      offices: [],
      appointmentDate: null,
      appointmentTime: null,
      appointmentDateSafari: null,
      searchedDays: 0,
      ui: {
        isLoading: false,
        notFound: false,
        toggleSafariDatepicker: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('scheduling', [
      'appointment',
      'patient',
      'address',
      'payment',
    ]),

    isSafariDesktop() {
      const agent = navigator.userAgent.toLowerCase();

      return /safari/gi.test(agent) && !/iphone/gi.test(agent);
    },

    localAppointment() {
      const empty = {
        doctor: {
          id: null,
          uuid: null,
        },
        doctorOffice: {
          id: null,
          uuid: null,
        },
        date: null,
        time: null,
        price: null,
      };

      if (
        this.appointment.modality === 'telemedicine'
        && this.appointmentTime
      ) {
        return {
          doctor: {
            id: this.appointmentTime['doctor_id'],
            uuid: this.appointmentTime.doctor,
          },
          doctorOffice: {
            id: this.appointmentTime['doctorOffice_id'],
            uuid: this.appointmentTime.doctorOffice,
          },
          date: this.appointmentDate,
          time: this.appointmentTime.time,
          price: this.appointmentTime.specialtyPrice,
        };
      }

      const office = this.offices.find((office) => office.appointmentTime);

      if (office) {
        return {
          doctor: {
            id: office.doctor.id,
            uuid: office.doctor.uuid,
          },
          doctorOffice: {
            id: office.doctorOffice.id,
            uuid: office.doctorOffice.uuid,
          },
          date: this.appointmentDate,
          time: office.appointmentTime.time,
          price: office.specialtyPrice,
        };
      }

      return empty;
    },

    isValid() {
      return Object.keys(this.localAppointment)
        .every((key) => this.localAppointment[key]);
    },
  },

  watch: {
    appointmentDate: {
      deep: true,
      immediate: false,
      handler(value, oldValue) {
        const [year, month, day] = value.split('-');
        const date = new Date(year, month - 1, day);

        if (isBefore(date, new Date())) {
          this.appointmentDate = format(new Date(), 'yyyy-MM-dd');
        }

        if (this.appointmentDate !== oldValue && this.appointment.specialty) {
          this.getAvailableTime();
        }
      },
    },

    'appointmentTime.time': function (next) {
      if (!next) {
        return;
      }

      const now = parseInt(format(addMinutes(new Date(), 15), 'yyyyMMddHHmm'), 10);
      const target = parseInt(format(new Date(`${this.appointmentDate}T${next}`), 'yyyyMMddHHmm'), 10);

      if (now > target) {
        this.handleError('Horário indisponível');
        this.$nextTick(() => {
          this.appointmentTime = null;
        });
      }
    },

    appointmentDateSafari: {
      deep: false,
      immediate: false,
      handler(next, prev) {
        if (next !== prev) {
          this.ui.toggleSafariDatepicker = false;
          const then = parseISO(next);

          if (isBefore(then, new Date())) {
            this.appointmentDateSafari = formatISO(new Date());
          }

          this.appointmentDate = format(parseISO(this.appointmentDateSafari), 'yyyy-MM-dd');
        }
      },
    },

    'localAppointment.time': {
      deep: false,
      immediate: false,
      handler(next, prev) {
        if (next && next !== prev) {
          this.scrollToSection('.appointment-date__call-to-action');
        }
      },
    },
  },

  created() {
    if (document.domain === 'paciente.drconecta.com.br') {
      window.fbq('track', 'PatientViewSchedule');

      window.gtag('event', 'conversion', { 'send_to': 'AW-415408362/W374CKj2wPYBEOrBisYB' });
    }

    this.today = format(new Date(), 'yyyy-MM-dd');
    this.appointmentDate = this.today;
    this.appointmentDateSafari = formatISO(new Date());
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),
    ...mapActions('scheduling', [
      'setAppointment',
      'setDoctor',
      'setOffice',
      'setPayment',
    ]),

    openSafariDatepicker() {
      if (this.isSafariDesktop) {
        this.ui.toggleSafariDatepicker = !this.ui.toggleSafariDatepicker;
      }
    },

    getAvailableTime() {
      this.appointmentTime = null;
      this.timetable = [];
      this.ui.isLoading = true;
      this.ui.notFound = false;
      this.offices = [];

      const coords = this.appointment.modality === 'presential'
        ? { latitude: this.address.latitude, longitude: this.address.longitude }
        : { latitude: null, longitude: null };

      this.toggleProgressBar();

      availableTime(
        this.partner.uuid,
        this.appointmentDate,
        this.appointment.specialty.id,
        coords,
        this.payment.method.value.toLowerCase(),
      )
        .getAvailableTime()
        .then((res) => {
          if (this.appointment.modality === 'telemedicine') {
            this.timetable = res;

            if (this.timetable.length === 0) {
              this.ui.notFound = true;
            }
          }

          if (this.appointment.modality === 'presential') {
            this.offices = res.map((item) => ({ ...item, appointmentTime: null }));
            this.offices = this.offices.filter((office) => office.timetable.length > 0);

            if (this.offices.length === 0) {
              this.ui.notFound = true;
            }
          }

          if (this.searchedDays > 0) {
            const [year, month, day] = this.appointmentDate.split('-');
            const finalDate = new Date(year, month - 1, day);
            const initialDate = subDays(finalDate, this.searchedDays);

            const msg = this.payment.method.name === 'boleto'
              ? `O dia mais próximo com horários disponíveis para pagamento
                com boleto bancário é <b>${format(finalDate, 'dd/MM/yyyy')}</b>.`
              : `Não foram encontrados horários para o dia
              <b>${format(initialDate, 'dd/MM/yyyy')}</b>. O dia mais próximo com horários
              disponíveis é <b>${format(finalDate, 'dd/MM/yyyy')}</b>.`;

            this.openModalDialog({
              type: 'alert',
              title: 'Atenção',
              text: msg,
              confirmText: 'Fechar',
              size: 'sm',
              fn: this.closeModalDialog,
            });
          }

          this.searchedDays = 0;
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        })
        .catch(() => {
          if (this.searchedDays < SEARCH_APPOINTMENTS_LIMIT) {
            this.searchedDays += 1;
            const [year, month, day] = this.appointmentDate.split('-');
            const date = addDays(new Date(year, month - 1, day), 1);

            this.appointmentDate = format(date, 'yyyy-MM-dd');
            this.ui.isLoading = true;
            this.toggleProgressBar(false);

            return;
          }

          this.ui.notFound = true;
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    goToPayment() {
      this.toggleProgressBar();
      this.setAppointment({
        date: this.localAppointment.date,
        time: this.localAppointment.time,
      });
      this.setOffice({ ...this.localAppointment.doctorOffice });
      this.setDoctor({ ...this.localAppointment.doctor });
      this.setPayment({ price: this.localAppointment.price });

      if (this.appointment.modality === 'presential') {
        const address = this.getAddress(
          this.offices.find((office) => office.appointmentTime).address,
        );
        this.setOffice({ address });
      }

      this.completeStep(3);
      this.toggleProgressBar(false);
    },

    getAddress(address) {
      const {
        complement,
        district,
        number,
        street,
        cep,
        city,
        uf,
      } = address;

      const formattedCity = city.charAt(0).toUpperCase() + city.slice(1);

      return `${street} ${number} ${complement ? `- ${complement}` : ''} - ${district}
        CEP ${cep.trim().replace(/^([\d]{5})([\d]{3})?/g, '$1-$2')} - ${formattedCity}/${uf}`;
    },

    searchTelemedicineAppointment() {
      this.setAppointment({ modality: 'telemedicine' });
      this.today = format(new Date(), 'yyyy-MM-dd');
      this.appointmentDate = this.today;
    },
  },
};
</script>

<style lang="scss">
.blend {
  &-enter {
    opacity: 0;
    display: none;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
